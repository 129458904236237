const __request = require(`./__request/__request_contentType_form`)

const recordCount = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: 'wallet/order/orderDetail',
        data: data
    }
    return __request(pParameter)
}
export default recordCount

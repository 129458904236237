const __request = require(`./__request/__request_contentType_json`);

const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/payment/notifyResult',
        data: data,
        appKey: `payment`
    }
    return __request(pParameter)
}
export default request
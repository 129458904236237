import Q from 'q';
import _ from 'underscore';
import PaymentMethodSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.3.2/payment-method-selector-200/index.vue';

import orderDetail from '@/lib/data-service/default/wallet_order_orderDetail'
import accountRecharge from '@/lib/data-service/default/wallet_pay_accountRecharge'
import paymentCallback from '@/lib/data-service/default/web_credit_refundNotify'

export default {
    data() {
        return {
            detail: {},
            loading: false,
            info: {
                title: '',
                show: false,
                url: ''
            },

            data_PaymentMethodSelector: {
                realAmount: ``,
            },
        }
    },
    components: {
        PaymentMethodSelector,
    },
    methods: {
        newPaySubmit(val) {
            const __this = this;
            const data_PaymentMethodSelector = __this.data_PaymentMethodSelector;

            const aPaymentMethodSelector = __this.$refs.aPaymentMethodSelector;
            aPaymentMethodSelector.init({
                mock_data: {
                    enable: false,
                    // enable: true,
                    // wait_seconds: 2,
                    wait_seconds: 0.5,
                },

                get_params() {
                    const p = {
                        companyId: val.companyId,
                        orderNo: val.orderNo,//订单编号
                        orderAmount: val.dealAmount,
                    };
                    return Q.resolve(p);
                },

                //支付成功事件处理方法
                pay_successful_event_handler() {
                    __this.info.show = false
                    // __this.$router.push({name: 'distributor-account-message', query: {type: 0}})
                    __this.$router.push({name: 'distributor-yinqianbao-back-account-management', query: {type: 1}})
                },

                //支付失败事件处理方法
                pay_failed_event_handler() {
                    __this.info.show = false
                    __this.$router.push({name: 'distributor-account-message', query: {type: 1}})
                    // __this.$router.push({name: 'distributor-yinqianbao-back-account-management', query: {type: 1}})
                },

                //选择的支付方式改变事件处理方法
                selected_payment_channel_entity_change_event_handler(args) {
                    const selected_payment_channel_entity = args.selected_payment_channel_entity;
                    const realAmount = selected_payment_channel_entity.realAmount;
                    console.log(`selected_payment_channel_entity=`, selected_payment_channel_entity);
                    console.log(`realAmount=`, realAmount);

                    data_PaymentMethodSelector.realAmount = realAmount;

                },
            });
        },
        submit(code) {
            let data = {
                orderNo: this.detail.orderNo,
                totalFee: this.detail.dealAmount,
                paymentChannel: code
            }
            return accountRecharge(data)
        },
        payCallback(tradeNo) {
            let _times = setInterval(() => {
                paymentCallback({tradeNo}).then(res => {
                    if (res.paymentStatus == 2) {
                        clearInterval(_times)
                        this.info.show = false
                        this.$router.push({name: 'distributor-account-message', query: {type: 0}})
                    } else if (res.paymentStatus == 3 || res.paymentStatus == 4) {
                        clearInterval(_times)
                        this.$router.push({name: 'distributor-account-message', query: {type: 1}})
                    }
                    setTimeout(() => {
                        clearInterval(_times)
                    }, 1000 * 300)
                })
            }, 3000)
        },
        getCode(title, url) {
            const h = this.$createElement;
            this.$msgbox({
                title: title,
                message: h('div', {style: 'display:flex;justify-content: center;'}, [
                    h('img', {style: 'width:300px;height:300px;', attrs: {src: url}})
                ]),
                showCancelButton: false,
                showConfirmButton: false,
                closeOnClickModal: false
            }).then(action => {
            });
        },
        async getDetail() {
            let orderId = this.$route.query.orderId
            let res = await orderDetail({orderId})
            this.detail = res
            this.newPaySubmit(res)
        }
    },
    activated() {
        this.getDetail()
        this.$nextTick(() => {
            let btn = document.querySelector('.button-container button')
            btn.innerHTML = '确认充值'
        })
    },

}
